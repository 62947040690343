exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-articles-tsx": () => import("./../../../src/pages/articles.tsx" /* webpackChunkName: "component---src-pages-articles-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kidokitapp-tsx": () => import("./../../../src/pages/kidokitapp.tsx" /* webpackChunkName: "component---src-pages-kidokitapp-tsx" */),
  "component---src-templates-agreements-template-tsx": () => import("./../../../src/templates/agreements-template.tsx" /* webpackChunkName: "component---src-templates-agreements-template-tsx" */),
  "component---src-templates-kidoku-template-tsx": () => import("./../../../src/templates/kidoku-template.tsx" /* webpackChunkName: "component---src-templates-kidoku-template-tsx" */),
  "component---src-templates-uzman-template-tsx": () => import("./../../../src/templates/uzman-template.tsx" /* webpackChunkName: "component---src-templates-uzman-template-tsx" */)
}

